import React, { useState } from "react";
import "./Experience.css";
import { data } from "./jobs";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Colors } from "../../common/colors";

export const Experience = () => {
  const [index, setIndex] = useState(0);
  const [jobs, setJobs] = useState(data);
  const { id, title, company, dates, duties } = jobs[index];

  return (
    <section className="experienceContainer">
      <h1>
        Experience
        <div className="bottomLine"></div>
      </h1>

      <section className="tabsWrap">
        <div className="experienceTabs">
          {jobs.map((job, curIndex) => {
            return (
              <button
                key={curIndex}
                className={`tabButton ${index === curIndex && "activeTab"}`}
                onClick={() => setIndex(curIndex)}
              >
                {job.company}
              </button>
            );
          })}
        </div>
        <div className="experienceInfo">
          <h2>{title}</h2>
          <h5>{company}</h5>
          <p id="datesWrapper">{dates}</p>
          {duties.map((duty, curIndex) => {
            return (
              <div id="dutyContainer" key={curIndex}>
                <HiChevronDoubleRight color={Colors.secondary} size={16} />
                <p style={{ marginTop: "12px" }}>{duty}</p>
              </div>
            );
          })}
        </div>
      </section>
      <div style={{ height: "100px" }}></div>
    </section>
  );
};
