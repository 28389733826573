import React, { useState } from "react";
import "./Projects.css";
import { Data } from "./Data";

export const Projects = () => {
  const [projects, updateProjects] = useState(Data);
  return (
    <div className="projectsContainer">
      <div style={{ height: "50px" }}></div>
      <h1>
        Work Samples
        <div className="underLine"></div>
      </h1>
      {projects.map((project, index) => (
        <div className="projectCard" key={index}>
          <div className="projectImgWrapper">
            {project.images.map((image, index) => (
              <div key={index}>
                <img src={image} alt="Project" />
              </div>
            ))}
          </div>
        </div>
      ))}
      <div style={{ height: "120px" }}></div>
    </div>
  );
};
