import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Initials from "../../../img/initials.png";
import { NavItems } from "./data";

export const MainBar = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let idx = 0;
    if (location.pathname === "/projects") {
      idx = 1;
    } else if (location.pathname === "/publications") {
      idx = 2;
    } else if (location.pathname === "/contact") {
      idx = 3;
    }
    setIndex(idx);
  }, [location]);

  return (
    <div className="navBar">
      <div className="nav-header">
        <img src={Initials} alt="Keshav Joshi" />
      </div>
      <div className="nav-items">
        {NavItems.map((item, idx) => (
          <Link
            key={idx}
            to={item.link}
            className={`${index == idx ? "activeNav" : "inActiveNav"}`}
            onClick={() => setIndex(idx)}
          >
            {item.name}
          </Link>
        ))}
      </div>
      {location.pathname === "/" && <div className="overlay"></div>}
    </div>
  );
};
