// Domain - FrontEnd, BackEnd, All
// Platform - Web, Mobile, All
// Category - Framework, Language, Hosting, Database, Tool, Library, Advanced
export const data = [
  {
    id: "1",
    name: "React",
    category: "framework",
    domain: "frontend",
    platform: "web",
    experience: 4,
  },
  {
    id: "2",
    name: "React Native",
    category: "framework",
    domain: "frontend",
    platform: "mobile",
    experience: 4,
  },
  {
    id: "3",
    name: "Javascript",
    category: "language",
    domain: "all",
    platform: "all",
    experience: 4,
  },
  {
    id: "4",
    name: "Typescript",
    category: "language",
    domain: "all",
    experience: 2,
  },
  {
    id: "5",
    name: "Node",
    category: "framework",
    domain: "backend",
    platform: "all",
    experience: 3,
  },
  {
    id: "6",
    name: "Express",
    category: "framework",
    domain: "backend",
    platform: "all",
    experience: 3,
  },
  {
    id: "7",
    name: "GraphQl",
    category: "language",
    domain: "all",
    platform: "all",
    experience: 2,
  },
  {
    id: "8",
    name: "MongoDB",
    category: "database",
    domain: "backend",
    platform: "all",
    experience: 2,
  },
  {
    id: "9",
    name: "HTML",
    category: "language",
    domain: "frontend",
    platform: "web",
    experience: 4,
  },
  {
    id: "10",
    name: "CSS",
    category: "language",
    domain: "frontend",
    platform: "web",
    experience: 4,
  },
  {
    id: "11",
    name: "Git",
    category: "tool",
    domain: "all",
    platform: "all",
    experience: 4,
  },
  {
    id: "12",
    name: "GitHub",
    category: "hosting",
    domain: "all",
    platform: "all",
    experience: 4,
  },
  {
    id: "13",
    name: "Bitbucket",
    category: "hosting",
    domain: "all",
    platform: "all",
    experience: 2,
  },
  {
    id: "14",
    name: "Heroku",
    category: "hosting",
    domain: "all",
    platform: "all",
    experience: 3,
  },
  {
    id: "15",
    name: "JAVA",
    category: "language",
    domain: "all",
    platform: "all",
    experience: 4,
  },
  {
    id: "16",
    name: "Python",
    category: "language",
    domain: "all",
    platform: "all",
    experience: 1,
  },
  {
    id: "17",
    name: "C/C++",
    category: "language",
    domain: "all",
    platform: "all",
    experience: 2,
  },
  {
    id: "18",
    name: "AWS",
    category: "hosting",
    domain: "all",
    platform: "all",
    experience: 1,
  },
  {
    id: "19",
    name: "Docker",
    category: "tool",
    domain: "all",
    platform: "all",
    experience: 1,
  },
  {
    id: "20",
    name: "Data Structure",
    category: "advanced",
    domain: "all",
    platform: "all",
    experience: 6,
  },
  {
    id: "21",
    name: "System Design",
    category: "advanced",
    domain: "all",
    platform: "all",
    experience: 2,
  },
  {
    id: "22",
    name: "Android Studio",
    category: "tool",
    domain: "frontend",
    platform: "mobile",
    experience: 3,
  },
  {
    id: "23",
    name: "Xcode",
    category: "tool",
    domain: "frontend",
    platform: "mobile",
    experience: 2,
  },
  {
    id: "24",
    name: "Jest",
    category: "tool",
    domain: "all",
    platform: "all",
    experience: 2,
  },
  {
    id: "25",
    name: "Angular",
    category: "framework",
    domain: "frontend",
    platform: "web",
    experience: 1,
  },
  {
    id: "26",
    name: "Redux",
    category: "library",
    domain: "frontend",
    platform: "all",
    experience: 2,
  },
  {
    id: "27",
    name: "Webpack",
    category: "tool",
    domain: "all",
    platform: "all",
    experience: 2,
  },
  {
    id: "28",
    name: "CircleCi",
    category: "hosting",
    domain: "all",
    platform: "all",
    experience: 1,
  },
  {
    id: "29",
    name: "NPM",
    category: "tool",
    domain: "all",
    platform: "all",
    experience: 4,
  },
  {
    id: "30",
    name: "Styled Components",
    category: "library",
    domain: "all",
    platform: "all",
    experience: 3,
  },
];
