import React from "react";
import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footerContainer">
      <span> &#169; 2023 | All rights reserved</span>
    </div>
  );
};
