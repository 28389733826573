import axios from "axios";
import { getBaseUrl } from "../utils/config";

const URL = `${getBaseUrl()}/api/v1/email/send-request`;

const sendEmail = (name, email, phone, message) => {
  return axios.post(URL, {
    name,
    email,
    phone,
    message,
  });
};

export const EmailService = {
  sendEmail,
};
