import React from "react";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaGithub,
  FaGoogle,
} from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";
import "./socialBar.css";

export const SocialBar = ({ size, type }) => {
  const openLink = (link) => {
    window.open(link, "_black");
  };

  return (
    <div
      className={`${
        type === "plain" ? "socialcontainerPlain" : "socialcontainerV1"
      }`}
    >
      <button
        id="btnLinkedin"
        onClick={() =>
          openLink("https://www.linkedin.com/in/keshav-joshi-aa832510a/")
        }
      >
        <FaLinkedinIn size={size === "large" ? 24 : 20} />
      </button>
      <button
        id="btnGithub"
        onClick={() => openLink("https://github.com/keshavjoshi-ca")}
      >
        <FaGithub size={size === "large" ? 24 : 20} />
      </button>
      <button
        id="btnLeetcode"
        onClick={() => openLink("https://leetcode.com/keshavjoshi_ca/")}
      >
        <SiLeetcode size={size === "large" ? 24 : 20} />
      </button>
      <button
        id="btnGoogle"
        onClick={() =>
          openLink(
            "https://scholar.google.co.in/citations?user=Yl1fGkwAAAAJ&hl=en"
          )
        }
      >
        <FaGoogle size={size === "large" ? 24 : 20} />
      </button>
      <button
        id="btnInstagram"
        onClick={() =>
          openLink("https://instagram.com/keshav.j0shi?igshid=OGQ5ZDc2ODk2ZA==")
        }
      >
        <FaInstagram size={size === "large" ? 24 : 20} />
      </button>
      <button
        id="btnTwitter"
        onClick={() => openLink("https://twitter.com/jkeshav23")}
      >
        <FaTwitter size={size === "large" ? 24 : 20} />
      </button>
    </div>
  );
};
