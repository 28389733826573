import React, { useReducer, useState } from "react";
import "./Contact.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { SocialBar } from "../common/SocialBar/SocialBar";
import { EmailService } from "../../services/EmailService";
import { initialState, reducer } from "./reducer";
import { TailSpin } from "react-loading-icons";

export const Contact = () => {
  const [state, disptach] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { name, email, phone, message } = state;
    if (
      name.length > 0 &&
      email.length > 0 &&
      phone.length > 0 &&
      message.length > 0
    ) {
      setLoading(true);
      EmailService.sendEmail(name, email, phone, message)
        .then(() => {
          disptach({ type: "StateClear" });
          toast("Form Sent", { type: "success" });
        })
        .catch((error) => toast("Failed " + error, { type: "error" }))
        .finally(() => setLoading(false));
    } else {
      toast("All fields are Mandatory ", { type: "error" });
    }
  };

  return (
    <>
      <ToastContainer position="top-left" />
      <div className="contactContainer">
        <div className="contactContent">
          <div className="contactForm">
            <form action="submit">
              <h2>Contact</h2>
              <div className="formRow">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={state.name}
                  onChange={(val) =>
                    disptach({ type: "Name", payload: val.target.value })
                  }
                  className="form-control"
                />
              </div>
              <div className="formRow">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  value={state.email}
                  onChange={(val) =>
                    disptach({ type: "Email", payload: val.target.value })
                  }
                  className="form-control"
                />
              </div>
              <div className="formRow">
                <label htmlFor="phone">Phone</label>
                <input
                  type="number"
                  name="phone"
                  maxLength={10}
                  value={state.phone === 0 ? "" : state.phone}
                  onChange={(val) =>
                    disptach({ type: "Phone", payload: val.target.value })
                  }
                  className="form-control"
                />
              </div>
              <div className="formRow">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  rows="5"
                  value={state.message}
                  onChange={(val) =>
                    disptach({ type: "Message", payload: val.target.value })
                  }
                  className="form-control"
                ></textarea>
              </div>
              <div className="formRow">
                <button
                  className="btn btn-success"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  {!loading ? "Submit" : <TailSpin width={24} height={24} />}
                </button>
              </div>
            </form>
          </div>
          <div className="contactLinks">
            <h2>About Me</h2>
            <div id="aboutPara">
              <p style={{ textAlign: "justify" }}>
                I'm a Software Engineer with around 4 of professional experience
                in the software development, working on diverse projects that
                have honed my skills in web and mobile application development.
                Throughout my career, I have built a strong foundation in React,
                React Native, and Node.js, which has enabled me to create
                robust, scalable, and efficient solutions for clients across
                various industries. I have a very keen interest in research and
                development. Throughout my journey, I have published one
                research paper and one book named "Dark Code". In my leisure
                time, I love to travel and respond to <b>FIFA</b> challanges.
              </p>
            </div>
            <div style={{ height: "64px" }}></div>
            <h2>Social Links</h2>
            <SocialBar size="large" type="plain" />
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
};
