import React, { useEffect, useState } from "react";
import { MainBar } from "./MainBar";
import "./navbar.css";
import { SideBar } from "./SideBar";

const ThresholdWidth = 991;

export const NavBar = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleScreenREsize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const listener = window.addEventListener("resize", handleScreenREsize);
    return () => window.removeEventListener("resize", listener);
  }, []);

  return <>{width < ThresholdWidth ? <SideBar /> : <MainBar />}</>;
};
