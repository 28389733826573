import React from "react";
import "./Services.css";
import { services } from "./data";

export const Services = () => {
  return (
    <section className="servicesContainer">
      <h1>
        Expertise
        <div className="bottomLine"></div>
      </h1>
      <div className="serviceList">
        {services.map((service, index) => {
          return (
            <article key={index} className="check">
              {service.icon}
              <h3>{service.name}</h3>
              <div className="cardBottomLine"></div>
              <p>{service.description}</p>
            </article>
          );
        })}
      </div>
      <div style={{ height: "100px" }}></div>
    </section>
  );
};
