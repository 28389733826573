import React, { useEffect, useState } from "react";
import "./Skills.css";
import { data } from "./data";

const Threshold = 991;

export const Skills = () => {
  const [skills, setSklls] = useState(data);
  const [category, setCategory] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let set = new Set();
    data.map((skill) => {
      set.add(skill.category);
    });

    setCategory(Array.from(set));
  }, []);

  useEffect(() => {
    const listener = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => window.removeEventListener("resize", listener);
  });

  return (
    <section className="skillsContainer">
      <h1>
        Technical Skills
        <div className="bottomLine"></div>
      </h1>
      <div className="skillWrap">
        {category.map((c, index) => {
          return (
            <div key={index} className="skillCard">
              <div className="skillCardIntro">{c}</div>
              <div className="skillCardContent">
                {skills
                  .filter((skill) => skill.category === c)
                  .map((skill, index) => {
                    return (
                      <div key={index} id="skillBean">
                        <h5>{skill.name}</h5>
                        {width > Threshold && (
                          <span>Years of experience: {skill.experience}</span>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ height: "100px" }}></div>
    </section>
  );
};
