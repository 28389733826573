import React, { useEffect, useState } from "react";
import Initials from "../../../img/initials.png";
import { RiMenu3Fill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { Colors } from "../../../common/colors";
import { Link, useLocation } from "react-router-dom";
import { NavItems } from "./data";

export const SideBar = () => {
  const [firstLoad, setFirstLoad] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const [index, setIndex] = useState(0);

  const handleNavigationClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    let idx = 0;
    if (location.pathname === "/projects") {
      idx = 1;
    } else if (location.pathname === "/publications") {
      idx = 2;
    } else if (location.pathname === "/contact") {
      idx = 3;
    }
    setIndex(idx);
  }, [location]);

  return (
    <>
      <div className="sidebarContainer">
        <div className="sideBarImg">
          <img src={Initials} alt="Keshav Joshi" />
        </div>
        {drawerOpen ? (
          <IoClose
            size={36}
            color={Colors.secondary}
            className="sideBarDrawerIcon"
            onClick={() => setDrawerOpen(!drawerOpen)}
          />
        ) : (
          <RiMenu3Fill
            size={32}
            color={Colors.secondary}
            className="sideBarDrawerIcon"
            onClick={() => {
              setFirstLoad(true);
              setDrawerOpen(!drawerOpen);
            }}
          />
        )}
      </div>
      <section
        className={`sideBarDrawer ${
          drawerOpen ? "drawerIn" : firstLoad ? "drawerOut" : ""
        }`}
      >
        {drawerOpen && (
          <>
            <div className="sideBarImgContainer">
              <img src={Initials} alt="Keshav Joshi" />
            </div>
            {NavItems.map((nav, idx) => (
              <Link
                to={nav.link}
                className={`${nav.animation} ${
                  index === idx ? "activeNav" : "inActiveNav"
                }`}
                onClick={handleNavigationClick}
                key={idx}
              >
                {nav.name}
              </Link>
            ))}
            <p> Toronto, Canada</p>
          </>
        )}
      </section>
    </>
  );
};
