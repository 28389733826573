export const NavItems = [
  {
    name: "Home",
    link: "/",
    animation: "drawerItemsAnimation1",
  },
  {
    name: "Projects",
    link: "/projects",
    animation: "drawerItemsAnimation4",
  },
  {
    name: "Publications",
    link: "/publications",
    animation: "drawerItemsAnimation3",
  },
  {
    name: "Contact",
    link: "/contact",
    animation: "drawerItemsAnimation2",
  },
];
