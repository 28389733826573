import React, { useState } from "react";
import DarkCodeFront from "../../img/DarkCodeFront.jpg";
import DarkCodeBack from "../../img/DarkCodeBack.jpg";
import Paper1 from "../../img/Paper1.jpg";
import Paper2 from "../../img/Paper2.jpg";
import "./Publication.css";

const paperThumbnails = [Paper1, Paper2];
const bookThumbnails = [DarkCodeFront, DarkCodeBack];
const DarkCodeLink =
  "https://www.amazon.com/Dark-Code-Keshav-Joshi/dp/9390724910";
const PaperLink =
  "https://www.researchgate.net/profile/Keshav-Joshi/publication/325597316_A_New_Approach_of_Text_Steganography_Using_ASCII_Values/links/5b17b62d0f7e9b68b41fa590/A-New-Approach-of-Text-Steganography-Using-ASCII-Values.pdf";

export const Publication = () => {
  const [paperValue, setPaperValue] = useState(0);
  const [bookValue, setBookValue] = useState(0);

  const handleReadClick = (link) => {
    window.open(link);
  };

  return (
    <div className="publicationContainer">
      <div style={{ height: "50px" }}></div>
      <h1>
        Publications
        <div className="underLine"></div>
      </h1>
      <div className="publicationWrapper">
        <div className="publicationCard">
          <div className="publicationCardHeader">
            <img src={paperThumbnails[paperValue]} alt="Paper IJERT" />
            <div className="thumbnailWrapper">
              {paperThumbnails.map((thumbnail, index) => (
                <img
                  key={index}
                  src={thumbnail}
                  alt="Paper"
                  onClick={() => setPaperValue(index)}
                />
              ))}
            </div>
          </div>
          <div className="publicationCardContent">
            <h1>"A new approach of text steganography using ASCII values"</h1>
            <div className="infoWrap">
              <h6>
                International Journal of Engineering Research and Technology
                (IJERT)
              </h6>
              <p>Vol. 7 Issue 05, May 2018</p>
              <p id="paperSummary">
                Developed an algorithm to implement text steganography by
                providing maximum payload capacity using ASCII values. Since I
                published this paper, it has been listed in many renowned
                journals such as Google Scholar, Semantics Scholar, IJCRT, etc
                and has been cited by atleast 17 other researchers.
              </p>
              <button
                className="btn btn-success"
                onClick={() => handleReadClick(PaperLink)}
              >
                Read Full Paper
              </button>
            </div>
          </div>
        </div>
        <div className="publicationCard cardReverse">
          <div className="publicationCardHeader">
            <img src={bookThumbnails[bookValue]} alt="Dark Code Front" />
            <div className="thumbnailWrapper">
              {bookThumbnails.map((thumbnail, index) => (
                <img
                  key={index}
                  src={thumbnail}
                  alt="DarkCode"
                  onClick={() => setBookValue(index)}
                />
              ))}
            </div>
          </div>
          <div className="publicationCardContent">
            <h1>"Dark Code"</h1>
            <div className="infoWrap">
              <h6>Think Beyond Limits</h6>
              <p></p>
              <p id="paperSummary">
                Published a book named Dark Code on the advance concepts of
                steganography and image processing. This work of mine contains
                some self structured algorithms to play with the depths of
                text/image steganography. It would be very exciting book to read
                as it would help readers to think beyond limits.
              </p>
              <button
                className="btn btn-success"
                onClick={() => handleReadClick(DarkCodeLink)}
              >
                Get Ebook
              </button>
            </div>
          </div>
        </div>
        <div style={{ height: "70px" }}></div>
      </div>
    </div>
  );
};
