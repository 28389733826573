import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./components/home/Home";
import { NavBar } from "./components/common/NavBar/NavBar";
import { Footer } from "./components/common/Footer/Footer";
import { Contact } from "./components/contact/Contact";
import { Publication } from "./components/publication/Publication";
import { Projects } from "./components/projects/Projects";

export const App = () => {
  return (
    <>
      <NavBar />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/publications" element={<Publication />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};
