export const data = [
  {
    id: "1",
    title: "Software Engineer",
    company: "Oanda",
    dates: "November 2021 - present",
    duties: [
      "Developed enterprise level trading solution for cross platforms using React and React Native.",
      "Handled scalable API middleware using the powerful combination of GraphQl and typescript.",
      "Lead the implementation of an important feature in trading from very scratch to production release. Solution is being used by thousands of daily active traders.",
      "Production deployments, team mentoring, pull requests, code reviews, unit testing and quality assurance.",
    ],
  },
  {
    id: "2",
    title: "Software Engineer",
    company: "Northline",
    dates: "June 2020 - November 2021",
    duties: [
      "Lead the design and development of many applications that communicates with embedded devices and display the real time traffic data, which is also currently being used by the City of Scugog, Pickering etc.",
      "Completely automated the entire in-house management system with user transaction dashboard that provides the rich visual summary of daily user transactions.",
      "Worked with video frame mapping application in python",
      "Developed a simple Rest API project in Nodejs and deployed it on Heroku",
    ],
  },
  {
    id: "3",
    title: "Student Researcher",
    company: "Wimtach",
    dates: "May 2019 - May 2020",
    duties: [
      "Designed and developed solutions for embedded systems, e-commerce applications and many others using technologies like React Native, Flutter, React, Android.",
      "Led the team of three in the application development process of condominium management system as per designs provided by the clients. Application was built in React Native usinf styled components for designing.",
      "Continuous team mentoring, code reviews, technical documentation, release management.",
      "Worked with Viro React, an Augmented Reality library for React Native.",
    ],
  },
];
