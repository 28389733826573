import React from "react";
import "./Home.css";
import Banner1 from "../../img/banner.png";
import { Services } from "../services/Services";
import { useNavigate } from "react-router-dom";
import { Experience } from "../experience/Experience";
import { Skills } from "../skills/Skills";
import { SocialBar } from "../common/SocialBar/SocialBar";

export const Home = () => {
  const navigation = useNavigate();

  const handleContactButton = () => {
    window.open(
      "https://drive.google.com/file/d/1WpL1WL2a1i3tw0Z3Ugr0_o9Xd6yenqib/view?usp=drive_link"
    );
  };

  return (
    <div className="mainContainer">
      <section className="container1">
        <div className="container1-intro">
          <article>
            <div className="topline"></div>
            <h1>Keshav Joshi</h1>
            <p>Full Stack Developer & React Native Expert</p>
            <button className="btn btn-primary" onClick={handleContactButton}>
              Resume
            </button>
            <SocialBar size="small" type="v1" />
          </article>
        </div>
        <div className="container1-content">
          <div className="pillarLeft"></div>
          <img src={Banner1} alt="Banner" />
        </div>
      </section>

      <Services />
      <Experience />
      <Skills />
    </div>
  );
};
