import App1 from "../../img/app1.png";
import App2 from "../../img/app2.png";
import App3 from "../../img/app3.png";
import Dash1 from "../../img/dash1.png";
import Dash2 from "../../img/dash2.png";
import Port1 from "../../img/port1.png";
import Port2 from "../../img/port2.png";
import Port3 from "../../img/port3.png";
import Port4 from "../../img/port4.png";
import PW1 from "../../img/pw1.png";
import PW2 from "../../img/pw2.png";
import PW3 from "../../img/pw3.png";
import PW4 from "../../img/pw4.png";

export const Data = [
  {
    name: "Mobile",
    description:
      "This project was built in React & React Native for BDG security services provider, a small-mid size comany in Tornto, GTA. Web solution was built in React to help admins to easli track their employee activities. Mobile solution was developed in React Native for the employees to manage their part of the duties. Apps were respectively deployed to play/app store. The entire backend was built in NodeJS and MongoDB and was deployed to Heroku.",
    dates: "May 2021",
    tags: ["React", "React Native", "NodeJS"],
    images: [App3, App2, App1, PW1, PW2, PW3, PW4],
  },
  {
    name: "Web",
    description:
      "Developed my own website from very scratch to showcase my designing and development skills. This portfolio is built in React and showcase all of my work experiences, skills, projects, research work and achievments.",
    dates: "July 2023",
    tags: ["React", "NodeJS"],
    images: [Port1, Port2, Port3, Port4],
  },
];
