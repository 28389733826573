export const initialState = {
  name: "",
  email: "",
  phone: 0,
  message: "",
};

export const reducer = (state, action) => {
  let stateChange = {};
  switch (action.type) {
    case "Name":
      stateChange = {
        ...state,
        name: action.payload,
      };
      return stateChange;
    case "Email":
      stateChange = {
        ...state,
        email: action.payload,
      };
      return stateChange;
    case "Phone":
      stateChange = {
        ...state,
        phone: action.payload,
      };
      return stateChange;
    case "Message":
      stateChange = {
        ...state,
        message: action.payload,
      };
      return stateChange;
    case "StateClear":
      return initialState;
  }
  return state;
};
