import React from "react";

import { ImMobile } from "react-icons/im";
import { HiCode } from "react-icons/hi";
import { FaReact } from "react-icons/fa";
import { Size } from "../../common/size";

export const services = [
  {
    name: "Web",
    summary: "",
    description: ` I have developed numerous web applications using React, leveraging its component-based architecture, virtual DOM, and state management. I have a deep understanding of React's core concepts.`,
    icon: <FaReact size={Size.ServiceCardIcon} />,
  },
  {
    name: "Mobile",
    summary: "",
    description: ` I possess extensive knowledge and hands-on experience in developing cross-platform mobile applications using React Native. I have successfully delivered many projects on App store and Play store.`,
    icon: <ImMobile size={Size.ServiceCardIcon} />,
  },
  {
    name: "Backend",
    summary: "",
    description: `I am well-versed in server-side JavaScript development with Node.js. I have built RESTful APIs using various Node.js frameworks and libraries to create scalable and high-performance backend systems`,
    icon: <HiCode size={Size.ServiceCardIcon} />,
  },
];
